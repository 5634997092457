import * as yup from "yup";
import { TKey, validation } from "../../../utils";

export const schema = (t: (val: string) => string) =>
    yup.object().shape({
        name: yup
            .string()
            .required(t(TKey.Common.Message.Validation.RequiredField))
            .max(validation.title.max),
        subTitle: yup.string().required().max(validation.title.max),
        description: yup.string().required().max(validation.description.max),
        days: yup
            .number()
            .nullable()
            .transform((value, originalValue) =>
                originalValue === "" ? null : value,
            ),
        budget: yup
            .number()
            .nullable()
            .transform((value, originalValue) =>
                originalValue === "" ? null : value,
            ),
        expense: yup
            .number()
            .nullable()
            .transform((value, originalValue) =>
                originalValue === "" ? null : value,
            ),
    });
